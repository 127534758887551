import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { useGiveSelfAccessMutation } from '@/API/views.api';
import { DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewPageRequestViewAccessModalOpen, setViewPageViewAccessDrawerOpen } from '@/store/slices/viewPage.slice';
import { ApiError } from '@/types/api.types';
import { openViewInNewTab } from '@/utils/url';

const REQUEST_ACCESS_ERROR_MESSAGE = 'There was an error trying to give you access for this view.';
const REQUEST_ACCESS_SUCCESS_MESSAGE = 'You now have access to the view.';

const RequestAccessModal = (): React.JSX.Element => {
  const [hasAccess, setHasAccess] = React.useState(false);

  const { isRequestViewAccessModalOpen, selectedViewId } = useAppSelector((state) => state.viewPage);

  const dispatch = useAppDispatch();

  const toast = useToast();

  const [giveSelfAccess, giveSelfAccessResponse] = useGiveSelfAccessMutation();

  const handleGiveAccess = async () => {
    try {
      const response = await giveSelfAccess(selectedViewId).unwrap();

      // Check if the view was deleted successfully
      if (response?.gainedAccess) {
        setHasAccess(true);

        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: REQUEST_ACCESS_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? REQUEST_ACCESS_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const handleClose = () => {
    dispatch(setViewPageRequestViewAccessModalOpen(false));
  };

  const handleEditAccess = () => {
    handleClose();
    dispatch(setViewPageViewAccessDrawerOpen(true));
  };

  const handleOpenInViewer = () => {
    handleClose();
    openViewInNewTab(selectedViewId);
  };

  if (!isRequestViewAccessModalOpen) return <></>;

  return (
    <Modal isOpen={isRequestViewAccessModalOpen} onClose={handleClose} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'} fontSize={'18px'}>
          You do not have access to this view.
        </ModalHeader>
        <ModalCloseButton />
        <VStack spacing={5} align={'center'}>
          <ModalBody>
            <Flex mb={6} justify={'center'}>
              How would you like to proceed?
            </Flex>
            <Stack spacing={4} direction={'row'} align={'center'}>
              {hasAccess && (
                <Button
                  size={'sm'}
                  colorScheme={'blue'}
                  onClick={handleOpenInViewer}
                  isLoading={giveSelfAccessResponse.isLoading}
                >
                  Open In Viewer
                </Button>
              )}
              {!hasAccess && (
                <Button
                  size={'sm'}
                  colorScheme={'blue'}
                  onClick={handleGiveAccess}
                  isLoading={giveSelfAccessResponse.isLoading}
                >
                  Give Me Access
                </Button>
              )}
              <Button variant={'outline'} size={'sm'} colorScheme={'blue'} onClick={handleEditAccess}>
                Edit Access
              </Button>
              <Button size={'sm'} colorScheme={'red'} onClick={handleClose} variant={'outline'}>
                Cancel
              </Button>
            </Stack>
          </ModalBody>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default RequestAccessModal;
