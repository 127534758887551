import { createSlice } from '@reduxjs/toolkit';

interface PersonnelPageState {
  isDepartmentListOpen: boolean;
  isHideInactiveToggled: boolean;
  isPersonnelMembershipDrawerOpen: boolean;
  personnelSearchFilterValue: string;
  personnelSearchValue: string;
  selectedDepartmentIds: number[];
  selectedPersonnelId: number | undefined;
}

const initialState: PersonnelPageState = {
  isDepartmentListOpen: false,
  isHideInactiveToggled: true,
  isPersonnelMembershipDrawerOpen: false,
  personnelSearchFilterValue: '',
  personnelSearchValue: '',
  selectedDepartmentIds: [],
  selectedPersonnelId: undefined,
};

const personnelPageSlice = createSlice({
  initialState,
  name: 'personnelPage',
  reducers: {
    setPersonnelPageHideInactiveToggled: (state, action) => {
      state.isHideInactiveToggled = action.payload;
    },
    setPersonnelPageIsDepartmentListOpen: (state, action) => {
      state.isDepartmentListOpen = action.payload;
    },
    setPersonnelPageSearchFilterValue: (state, action) => {
      state.personnelSearchFilterValue = action.payload;
    },
    setPersonnelPageSearchValue: (state, action) => {
      state.personnelSearchValue = action.payload;
    },
    setPersonnelPageSelectedDepartmentIds: (state, action) => {
      state.selectedDepartmentIds = action.payload;
    },
  },
});

export const {
  setPersonnelPageHideInactiveToggled,
  setPersonnelPageIsDepartmentListOpen,
  setPersonnelPageSearchFilterValue,
  setPersonnelPageSearchValue,
  setPersonnelPageSelectedDepartmentIds,
} = personnelPageSlice.actions;

export default personnelPageSlice.reducer;
