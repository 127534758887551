// Use @reduxjs/toolkit/query/react as it provides a React-specific entry point
// that automatically generates hooks corresponding to the defined endpoints

import { HttpMethods } from 'msw';

import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import { uiSlice } from '@/store/slices/ui.slice';
import Department from '@/types/department.types';

export const departmentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], void>({
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          dispatch(uiSlice.actions.toggleIsLoadingDepartments());
          await queryFulfilled;
        } catch (err) {
          console.log(err);
          // ToDo: Add error handling
        } finally {
          dispatch(uiSlice.actions.toggleIsLoadingDepartments());
        }
      },
      query: () => {
        return {
          body: { templates: [] },
          method: HttpMethods.POST,
          url: `${Endpoints.Departments}`,
        };
      },
      transformResponse: (response: Department[]) => {
        if(!response) return [];

        response.sort((a, b) => a.name.localeCompare(b.name));
        return response;
      },
    }),
  }),
});

export const { useGetDepartmentsQuery } = departmentsApi;
