import { HStack, Spacer, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import ViewLayoutBlock from '@/components/view-editor/ViewLayoutBlock';
import ViewLayoutDisplayRange from '@/components/view-editor/ViewLayoutDisplayRange';
import ViewLayoutGroupBy from '@/components/view-editor/ViewLayoutGroupBy';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewEditorDraftThemeDataLayout,
  setViewEditorDraftThemeDataLayoutLeftColumnType,
  setViewEditorDraftThemeDataLayoutRange,
} from '@/store/slices/viewEditor.slice';
import { ViewLayoutType } from '@/types/view.types';

import ViewLayoutList from './ViewLayoutList';

enum ViewLayoutTabIndices {
  Standard,
  Columns,
  Calendar,
  Gantt,
  List,
  Block,
}

const selectedTabStyle = {
  color: 'white',
};

const ViewLayoutTypeTabIndices = Object.values(ViewLayoutType);

const ViewLayout = (): React.JSX.Element => {
  const {
    viewDraft: view,
  } = useAppSelector((state) => state.viewEditor);

  const dispatch = useAppDispatch();

  const displayRange = view?.theme?.data?.range ?? 'month';
  const groupBy = view?.theme?.data?.GridSettings_leftColumnType ?? 'assignment';
  const tabSelectorIndex = ViewLayoutTypeTabIndices.includes(view.theme.data.layout)
    ? ViewLayoutTypeTabIndices.indexOf(view.theme.data.layout ?? ViewLayoutTabIndices.Standard)
    : ViewLayoutTabIndices.Standard;

  const handleDisplayRangeChange = (value: string) => dispatch(setViewEditorDraftThemeDataLayoutRange(value));

  const handleGroupByChange = (value: string) => dispatch(setViewEditorDraftThemeDataLayoutLeftColumnType(value));

  const handleTabSelectorChange = (index: number) => {
    const layout = ViewLayoutTypeTabIndices[index] ?? ViewLayoutType.STANDARD;
    let range = displayRange;

    // Handle applying the correct views range for the selected layout
    switch (layout) {
      case ViewLayoutType.STANDARD:
      case ViewLayoutType.COLUMNS:
      case ViewLayoutType.CALENDAR:
        range = 'month';
        break;
      case ViewLayoutType.GANTT:
      case ViewLayoutType.LIST:
        range = 'day';
        break;
      case ViewLayoutType.BLOCK:
        range = 'anchored';
    }

    dispatch(setViewEditorDraftThemeDataLayout(layout));
    dispatch(setViewEditorDraftThemeDataLayoutRange(range));
  };

  return (
    <VStack align={'left'}>
      <Text>View Layout</Text>
      <Tabs variant={'unstyled'} position={'relative'} index={tabSelectorIndex} onChange={handleTabSelectorChange}>
        <TabList>
          <Tab _selected={selectedTabStyle}>Standard</Tab>
          <Tab _selected={selectedTabStyle}>Columns</Tab>
          <Tab _selected={selectedTabStyle}>Calendar</Tab>
          <Tab _selected={selectedTabStyle}>Gantt</Tab>
          <Tab _selected={selectedTabStyle}>List</Tab>
          <Tab _selected={selectedTabStyle}>Block</Tab>
        </TabList>
        <TabIndicator
          bg={'blue.500'}
          borderRadius={'10px'}
          height={'40px'}
          mt={'-40px'}
          zIndex={-1}
          position={'inherit'}
          textColor={'white'}
        />
        <Spacer h={'10px'} borderBottom={'1px solid'} color={'blue.500'} opacity={'0.4'} />
        <TabPanels>
          {/* Standard */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndices.Standard && (
              <HStack w={'400px'} alignItems={'space-between'} gap={10}>
                <ViewLayoutDisplayRange
                  onChange={handleDisplayRangeChange}
                  options={[
                    { label: 'Month', value: 'month' },
                    { label: 'Week', value: 'week' },
                  ]}
                  selected={displayRange}
                />
                <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
              </HStack>
            )}
          </TabPanel>
          {/* Columns */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndices.Columns && (
              <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
            )}
          </TabPanel>
          {/* Calendar */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndices.Calendar && (
              <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
            )}
          </TabPanel>
          {/* Gantt */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndices.Gantt && (
              <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
            )}
          </TabPanel>
          {/* List */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndices.List && (
              <ViewLayoutList
                displayRange={displayRange}
                groupBy={groupBy}
                handleDisplayRangeChange={handleDisplayRangeChange}
                handleGroupByChange={handleGroupByChange}
              />
            )}
          </TabPanel>
          {/* Block */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndices.Block && (
              <ViewLayoutBlock
                displayRange={displayRange}
                groupBy={groupBy}
                handleDisplayRangeChange={handleDisplayRangeChange}
                handleGroupByChange={handleGroupByChange}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default ViewLayout;
