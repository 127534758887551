import './index.css';

import { ChakraProvider } from '@chakra-ui/react';
import { enableMapSet } from 'immer';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '@/App';
import { AuthProvider } from '@/Providers/Auth.provider';

import { store } from './store/store';

enableMapSet();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ChakraProvider>
      <CookiesProvider>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </DndProvider>
        </Provider>
      </CookiesProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
