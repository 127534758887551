import { Td, Tr } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender, Row } from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';
import { FaBars } from 'react-icons/fa';

import styles from './styles.module.scss';

/*
 * Sourced from: https://codesandbox.io/p/sandbox/github/tanstack/table/tree/main/examples/react/row-dnd?embed=1&file=%2Fsrc%2Fmain.tsx%3A79%2C1-101%2C4
 * */

interface DraggableRowProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: Row<any>;
  rowUniqueKey: string;
  isActive?: boolean;
  isHidden?: boolean;
  isSelected?: boolean;
}

const DraggableRow = (props: DraggableRowProps): React.JSX.Element => {
  const { isActive = false, isHidden = false, isSelected = false, row, rowUniqueKey } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.original[rowUniqueKey],
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const draggableRowClass = classNames(styles.draggableRowClass, {
    [styles.active]: isActive,
    [styles.hidden]: isHidden,
  });

  const draggableCellClass = classNames(styles.text, {
    [styles.selected]: isSelected,
  });

  const dragControlClass = classNames(styles.text);

  return (
    <Tr className={draggableRowClass} style={{ ...style }}>
      <Td width={50} padding={0} ref={setNodeRef} paddingLeft={5} className={dragControlClass}>
        <button {...listeners} {...attributes}>
          <FaBars />
        </button>
      </Td>
      {row.getVisibleCells().map((cell) => (
        <Td key={cell.id} padding={0} className={draggableCellClass}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
    </Tr>
  );
};

export default DraggableRow;
