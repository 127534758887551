/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_VIEW } from '@/constants/defaults';
import { ViewEditorDrawerMode, ViewEditorDrawerTabIndices } from '@/constants/enums';
import { AssignmentType } from '@/types/assignment.types';
import { DepartmentSlim } from '@/types/department.types';
import { PersonnelItemColored, PersonnelType } from '@/types/personnel.types';
import { TemplateSlim } from '@/types/template.types';
import View from '@/types/view.types';

interface UIState {
  assignmentTypes: AssignmentType[];
  departmentOptions: DepartmentSlim[];
  isLoading: boolean;
  isOpen: boolean;
  mode: ViewEditorDrawerMode;
  navIsBlocked: boolean;
  personnelTypes: PersonnelType[];
  selectedDepartments: number[];
  selectedTemplates: number[];
  showConfirmRemoveDepartment: boolean;
  showConfirmSaveChanges: boolean;
  tabIndex: ViewEditorDrawerTabIndices;
  templateOptions: TemplateSlim[];
}

interface ViewEditorState {
  isDirty: boolean;
  uiState: UIState;
  viewDraft: View;
  viewOriginal: View | undefined;
}

const defaultUIState: UIState = {
  assignmentTypes: [],
  departmentOptions: [],
  isLoading: false,
  isOpen: false,
  mode: ViewEditorDrawerMode.CREATE,
  navIsBlocked: false,
  personnelTypes: [],
  selectedDepartments: [],
  selectedTemplates: [],
  showConfirmRemoveDepartment: false,
  showConfirmSaveChanges: false,
  tabIndex: ViewEditorDrawerTabIndices.VIEW_PROPERTIES,
  templateOptions: [],
};

const initialState: ViewEditorState = {
  isDirty: false,
  uiState: defaultUIState,
  viewDraft: DEFAULT_VIEW,
  viewOriginal: undefined,
};

export const viewEditorSlice = createSlice({
  initialState,
  name: 'viewEditor',
  reducers: {
    initializeViewEditor: (state, action) => {
      state.uiState.mode = action.payload.mode;
      state.uiState.isLoading = action.payload.isLoading;
    },
    /** Passing an initial view draft indicates an existing view will be edited.
     * This reducer will initialize the view editor draft and original view drafts
     * with the action payload. Any changes made to the draft can be compared against
     * the original, indicating save logic needs to be performed. */
    initializeViewEditorDraft: (state, action) => {
      state.viewDraft = action.payload;
      state.uiState.selectedDepartments = action.payload.filter.on_departments;
      state.uiState.selectedTemplates = action.payload.filter.on_templates;
      state.viewOriginal = action.payload;
    },
    setViewEditorDraftAssignmentTypes: (state, action) => {
      state.uiState.assignmentTypes = action.payload;
    },
    /** Sets the assignments for the view editor draft
     * @param {ViewEditorDraftColoredItem[]} action.payload - An array of themeable assignments containing
     * the assignment id, slot color, and text color to be included in the view.
     * */
    setViewEditorDraftAssignments: (state, action) => {
      state.viewDraft.filter.on_assignments = action.payload;
    },
    /** Sets the department ids for the view editor draft
     * @param {number[]} action.payload - An array of department ids to be included in the view.
     * */
    setViewEditorDraftAutoAddAssignments: (state, action) => {
      state.viewDraft.filter.auto_add_assignments = action.payload;
    },
    setViewEditorDraftAutoAddPersonnel: (state, action) => {
      state.viewDraft.filter.auto_add_personnel = action.payload;
    },
    setViewEditorDraftDepartmentIds: (state, action) => {
      state.viewDraft.filter.on_departments = action.payload;
      state.uiState.selectedDepartments = action.payload;
    },
    /** Sets the name for the view editor draft
     * @param {string} action.payload - The name to be used for the view. Must be unique.
     */
    setViewEditorDraftName: (state, action) => {
      state.viewDraft.name = action.payload;
    },
    setViewEditorDraftNavIsBlocked: (state, action) => {
      state.uiState.navIsBlocked = action.payload;
    },
    /** Sets the personnel for the view editor draft
     * @param {ViewEditorDraftColoredItem[]} action.payload - An array of themeable personnel containing
     * the personnel id, slot color, and text color to be included in the view.
     */
    setViewEditorDraftPersonnel: (state, action) => {
      state.viewDraft.filter.on_personnel = (action.payload as PersonnelItemColored[]).map((personnelItem) => {
        return {
          color: personnelItem.color,
          colorText: personnelItem.colorText,
          id: personnelItem.id,
        };
      });
    },
    setViewEditorDraftPersonnelTypes: (state, action) => {
      state.uiState.personnelTypes = action.payload;
    },
    setViewEditorDraftSortByAssignment: (state, action) => {
      state.viewDraft.filter.sort_assignments_by = action.payload;
    },
    setViewEditorDraftSortByPersonnel: (state, action) => {
      state.viewDraft.filter.sort_personnel_by = action.payload;
    },
    /** Sets the tally ids for the view editor draft
     * @param {number[]} action.payload - An array of tally ids to be included in the view.
     */
    setViewEditorDraftTallies: (state, action) => {
      state.viewDraft.filter.on_tallies = action.payload;
    },
    /** Sets the template ids for the view editor draft
     * @param {number[]} action.payload - An array of template ids to be included in the view.
     */
    setViewEditorDraftTemplateIds: (state, action) => {
      state.viewDraft.filter.on_templates = action.payload;
      state.uiState.selectedTemplates = action.payload;
    },
    setViewEditorDraftThemeData: (state, action) => {
      Object.assign(state.viewDraft.theme.data, action.payload);
    },
    setViewEditorDraftThemeDataLayout: (state, action) => {
      state.viewDraft.theme.data.layout = action.payload;
    },
    setViewEditorDraftThemeDataLayoutDataType: (state, action) => {
      state.viewDraft.theme.data.dataType = action.payload;
    },
    setViewEditorDraftThemeDataLayoutLeftColumnType: (state, action) => {
      state.viewDraft.theme.data.GridSettings_leftColumnType = action.payload;
    },
    setViewEditorDraftThemeDataLayoutListColumns: (state, action) => {
      state.viewDraft.theme.data.listColumns = action.payload;
    },
    setViewEditorDraftThemeDataLayoutRange: (state, action) => {
      state.viewDraft.theme.data.range = action.payload;
    },
    setViewEditorIsLoading: (state, action) => {
      state.uiState.isLoading = action.payload;
    },
    setViewEditorIsOpen: (state, action) => {
      state.uiState.isOpen = action.payload;
    },
    setViewEditorMode: (state, action) => {
      state.uiState.mode = action.payload;
    },
    setViewEditorShowConfirmRemoveDepartment: (state, action) => {
      state.uiState.showConfirmRemoveDepartment = action.payload;
    },
    setViewEditorShowConfirmSaveChanges: (state, action) => {
      state.uiState.showConfirmSaveChanges = action.payload;
    },
    setViewEditorTabIndex: (state, action) => {
      state.uiState.tabIndex = action.payload;
    },
    viewEditorClose: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  initializeViewEditor,
  initializeViewEditorDraft,
  setViewEditorDraftAssignmentTypes,
  setViewEditorDraftAssignments,
  setViewEditorDraftAutoAddAssignments,
  setViewEditorDraftAutoAddPersonnel,
  setViewEditorDraftDepartmentIds,
  setViewEditorDraftName,
  setViewEditorDraftNavIsBlocked,
  setViewEditorDraftPersonnel,
  setViewEditorDraftPersonnelTypes,
  setViewEditorDraftSortByAssignment,
  setViewEditorDraftSortByPersonnel,
  setViewEditorDraftTallies,
  setViewEditorDraftTemplateIds,
  setViewEditorDraftThemeData,
  setViewEditorDraftThemeDataLayout,
  setViewEditorDraftThemeDataLayoutDataType,
  setViewEditorDraftThemeDataLayoutLeftColumnType,
  setViewEditorDraftThemeDataLayoutListColumns,
  setViewEditorDraftThemeDataLayoutRange,
  setViewEditorIsLoading,
  setViewEditorIsOpen,
  setViewEditorMode,
  setViewEditorShowConfirmRemoveDepartment,
  setViewEditorShowConfirmSaveChanges,
  setViewEditorTabIndex,
  viewEditorClose,
} = viewEditorSlice.actions;

export default viewEditorSlice.reducer;
