import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store/store';
import { DepartmentSlim } from '@/types/department.types';
import { TemplateSlim } from '@/types/template.types';


interface DepartmentsAndTemplatesState {
  departmentIdTemplateMap: { [key: number]: number[] };
  departments: DepartmentSlim[];
  templates: TemplateSlim[];
}

const initialState: DepartmentsAndTemplatesState = {
  departmentIdTemplateMap: {},
  departments: [],
  templates: [],
};

export const DepartmentsAndTemplatesSlice = createSlice({
  initialState,
  name: 'departmentsAndTemplates',
  reducers: {
    initializeDepartmentsAndTemplates: (state, action) => {
      state.departmentIdTemplateMap = action.payload.departmentIdTemplateMap;
      state.departments = action.payload.departments;
      state.templates = action.payload.templates;
    },
  },
});

const selectDepartmentIdTemplateMap = (state: RootState) => state.departmentsAndTemplates.departmentIdTemplateMap;
const selectTemplates = (state: RootState) => state.departmentsAndTemplates.templates;

export const makeSelectTemplatesForSelectedDepartments = () =>
  createSelector(
    [selectDepartmentIdTemplateMap, selectTemplates, (_state: RootState, selectedDepartmentIds: number[]) => selectedDepartmentIds],
    (departmentIdTemplateMap, templates, selectedDepartmentIds) => {
      const templatesForSelectedDepartmentsMap = new Map<number, TemplateSlim>();

      selectedDepartmentIds.forEach((departmentId) => {
        const templatesForDepartment = departmentIdTemplateMap?.[departmentId] ?? [];

        templatesForDepartment.forEach((templateId) => {
          if (!templatesForSelectedDepartmentsMap.has(templateId)) {
            const template = templates.find((template) => template.templateId === templateId);
            if (template) {
              templatesForSelectedDepartmentsMap.set(templateId, template);
            }
          }
        });
      });

      return Array.from(templatesForSelectedDepartmentsMap.values()).sort((a, b) =>
        a.templateName.localeCompare(b.templateName),
      );
    },
  );

export const selectTemplatesForSelectedDepartments = (state: RootState, selectedDepartmentIds: number[]) => makeSelectTemplatesForSelectedDepartments()(state, selectedDepartmentIds);

export const {
  initializeDepartmentsAndTemplates,
} = DepartmentsAndTemplatesSlice.actions;

export default DepartmentsAndTemplatesSlice.reducer;

