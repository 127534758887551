import { Box, Spinner, Text } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import React from 'react';
import { Navigate } from 'react-router';

import { ROUTES } from '@/constants/config';
import { useAppSelector } from '@/store/hooks';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

const LoadingPage = () => {
  const authState = useAppSelector((state) => state.auth, isEqual);

  return React.useMemo(() => {
    if (authState.isPending) {
      return (
        <Box data-testid={ELEMENT_DATA_TEST_IDS.LOADING_PAGE}>
          <Text>Loading</Text>
          <Spinner />
        </Box>
      );
    }

    if (authState.accessToken) {
      return <Navigate to={ROUTES.VIEWS} />;
    }

    return <Navigate to={ROUTES.UNAUTHORIZED} replace />;
  }, [authState]);
};

export default LoadingPage;
