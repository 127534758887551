import { Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface ViewLayoutGroupByProps {
  onChange: (value: string) => void;
  selected: string;
}

const ViewLayoutGroupBy = (props: ViewLayoutGroupByProps): JSX.Element => {
  const { onChange, selected } = props;

  return (
    <VStack alignItems={'space-between'} align={'left'}>
      <Text>Group By</Text>
      <RadioGroup onChange={onChange} value={selected}>
        <VStack align={'left'} justifyContent={'left'} alignItems={'space-between'}>
          <Radio value="assignment">Assignment</Radio>
          <Radio value="personnel">Personnel</Radio>
        </VStack>
      </RadioGroup>
    </VStack>
  );
};

export default ViewLayoutGroupBy;
