import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { apiSlice } from '@/API/api.slice';
import authSlice from '@/store/slices/auth.slice';
import departmentsAndTemplatesSlice from '@/store/slices/departmentsAndTemplates.slice';
import personnelMembershipDrawerSlice from '@/store/slices/personnelMembershipDrawer.slice';
import personnelPageSlice from '@/store/slices/personnelPage.slice';
import uiSlice from '@/store/slices/ui.slice';
import viewAccessSlice from '@/store/slices/viewAccess.slice';
import viewEditorSlice from '@/store/slices/viewEditor.slice';
import viewPageSlice from '@/store/slices/viewPage.slice';

import viewsSlice from './slices/views.slice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  reducer: {
    auth: authSlice,
    departmentsAndTemplates: departmentsAndTemplatesSlice,
    personnelMembershipDrawer: personnelMembershipDrawerSlice,
    personnelPage: personnelPageSlice,
    uiStore: uiSlice,
    viewAccess: viewAccessSlice,
    viewEditor: viewEditorSlice,
    viewPage: viewPageSlice,
    viewsCollection: viewsSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
