/* eslint-disable no-magic-numbers */
import { object, string } from 'yup';

import UIConfig from '@/config/ui.config';

const viewNameSchema = object({
  viewName: string()
    .trim()
    .required('View name cannot be blank.')
    .min(UIConfig.MINIMUM_VIEW_NAME_LENGTH, 'View name must be at least three characters, excluding spaces.'),
});

export default viewNameSchema;
