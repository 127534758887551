import { Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface RadioOption {
  label: string;
  value: string;
}

interface ViewLayoutDisplayRangeProps {
  onChange: (value: string) => void;
  options: RadioOption[];
  selected: string;
}

const ViewLayoutDisplayRange = (props: ViewLayoutDisplayRangeProps): JSX.Element => {
  const { onChange, options, selected } = props;

  return (
    <VStack alignItems={'space-between'} align={'left'}>
      <Text>Display Range</Text>
      <RadioGroup value={selected} onChange={onChange}>
        <VStack align={'left'} justifyContent={'left'} alignItems={'space-between'}>
          {options.map((option: RadioOption) => (
            <Radio value={option.value} key={option.value}>
              {option.label}
            </Radio>
          ))}
        </VStack>
      </RadioGroup>
    </VStack>
  );
};

export default ViewLayoutDisplayRange;
