/* eslint-disable camelcase */
import { HttpStatusCode } from 'axios';
import { HttpMethods } from 'msw';

import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import { PaginationFilter, ResponseData } from '@/types/api.types';
import {
  ActiveDays,
  PersonnelItemSlim,
  PersonnelListItem,
  PersonnelName,
  PersonnelType,
  PersonnelTypeDTO,
  PersonnelViewAccessBody,
  PersonnelViewMembership,
} from '@/types/personnel.types';
import Comparisons from '@/utils/comparisons';
import { buildQueryParams, QueryParams } from '@/utils/queryParams';

interface PersonnelDTO {
  active: boolean;
  active_days: ActiveDays;
  assignments: number;
  departments: number[];
  expired: boolean;
  groups: number;
  id: number;
  is_staff: boolean;
  last_modified: string;
  name: PersonnelName;
  schedule_type: string;
  type: PersonnelType | null;
  views: number;
}

interface PersonnelResponse extends PaginationFilter {
  Data: PersonnelDTO[];
}

interface PaginatedPersonnelList {
  pagination: PaginationFilter;
  personnelList: PersonnelListItem[];
}

export interface PersonnelByDeptIdDTO {
  compact_name: string;
  display_name: string;
  emp_id: number;
  expired: boolean;
  first_name: string;
  last_active_date: string;
  last_name: string;
  ptype_id: number;
}

export interface PersonnelListParams {
  dept_list?: string;
  expired?: boolean;
  filters?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  hideInactive?: boolean;
}

export interface PersonnelSearchBody {
  DepartmentIds?: number[];
  OrderByLastName?: 'asc' | 'desc';
  PersonnelIds?: number[];
  SearchQuery?: string;
  Slim?: boolean;
  ViewId?: number;
}

export const personnelApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonnel: builder.query<PaginatedPersonnelList, PersonnelListParams>({
      query: (params) => {
        return `${Endpoints.VMPersonnel}?${buildQueryParams(params as QueryParams)}`;
      },
      transformResponse: (response: PersonnelResponse) => {
        const { PageNumber, PageSize, TotalPages, TotalRecords } = response;

        const personnelList = response.Data.map((personnelItem) => {
          const activeDays = personnelItem.active_days;
          const lastModified = personnelItem.last_modified;

          return {
            expired: personnelItem.expired,
            id: personnelItem.id,
            lastActiveDate: activeDays.last,
            lastModified,
            name: personnelItem.name,
          };
        });

        return {
          pagination: {
            // eslint-disable-next-line no-magic-numbers
            PageIndex: PageNumber - 1 < 0 ? 0 : PageNumber - 1,
            PageNumber,
            PageSize,
            TotalPages,
            TotalRecords,
          },
          personnelList,
        };
      },
    }),
    getPersonnelBySearch: builder.query<PersonnelItemSlim[], PersonnelSearchBody>({
      query: (searchBody: PersonnelSearchBody) => {
        return {
          body: searchBody,
          method: HttpMethods.POST,
          url: Endpoints.PersonnelSearch,
        };
      },
      transformResponse: (response: PersonnelByDeptIdDTO[]) => {
        if (!response) return [];

        return response
          .map((personnelItem) => {
            return {
              expired: personnelItem.expired,
              id: personnelItem.emp_id,
              isSelected: false,
              lastActiveDate: personnelItem.last_active_date,
              name: personnelItem.display_name,
              pTypeId: personnelItem.ptype_id,
              personnelName: {
                display: personnelItem.display_name,
                first: personnelItem.first_name,
                last: personnelItem.last_name,
              },
            };
          })
          .filter((personnelItem) => !Comparisons.isLBSAccount(personnelItem.id));
      },
    }),
    getPersonnelTypes: builder.query<PersonnelType[], void>({
      query: () => Endpoints.PersonnelType,
      transformResponse: (response: PersonnelTypeDTO[]) => {
        if (!response) return [];

        return response.map((personnelType) => {
          return {
            name: personnelType.name,
            ptypeId: personnelType.ptype_id,
          };
        });
      },
    }),
    getPersonnelViewMembershipAndAccess: builder.query<PersonnelViewMembership, number>({
      query: (empId) => `${Endpoints.VMPersonnelAccess}/${empId}`,
    }),
    updatePersonnelViewMembershipAndAccess: builder.mutation<ResponseData | null, PersonnelViewAccessBody>({
      query: (body) => ({
        body: {
          accessible_views: body.viewIds,
          member_of_filters: body.filterIds,
        },
        method: HttpMethods.PUT,
        url: `${Endpoints.Personnel}?id=${body.id}`,
      }),
      transformResponse: (_response, meta) => {
        // Return true if the response status === 204 because the api does not return any response data when update is successful
        if (meta?.response?.status === HttpStatusCode.NoContent) {
          return { updated: true } as ResponseData;
        }
        return null;
      },
    }),
  }),
});

export const {
  useGetPersonnelQuery,
  useGetPersonnelBySearchQuery,
  useGetPersonnelTypesQuery,
  useLazyGetPersonnelTypesQuery,
  useGetPersonnelViewMembershipAndAccessQuery,
  useUpdatePersonnelViewMembershipAndAccessMutation,
} = personnelApi;
