import { Skeleton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

import UIConfig from '@/config/ui.config';

const PaginatedTableSkeleton = () => {
  const getBodyRows = () => {
    const rows = [];
    for (let i = 0; i < UIConfig.DEFAULT_TABLE_ROW_COUNT; i++) {
      rows.push(
        <Tr key={`table-skeleton-row-${i}`}>
          <Td>
            <Skeleton height="30px" isLoaded={false} startColor="blue.300" endColor="blue.500" />
          </Td>
        </Tr>,
      );
    }
    return rows;
  };

  return (
    <>
      <Skeleton h={'25px'} isLoaded={false} w={'600px'} startColor="blue.300" endColor="blue.500" />
      <Table variant={'striped'} colorScheme={'gray'}>
        <Thead>
          <Tr>
            <Th>
              <Skeleton height="30px" isLoaded={false} startColor="blue.300" endColor="blue.500" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>{getBodyRows()}</Tbody>
      </Table>
    </>
  );
};

export default PaginatedTableSkeleton;
