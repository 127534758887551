import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import React from 'react';
import { Navigate } from 'react-router';

import { ROUTES } from '@/constants/config';
import { useAppSelector } from '@/store/hooks';
import { selectAuth } from '@/store/slices/auth.slice';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

const UnauthorizedPage = (): JSX.Element => {
  const authState = useAppSelector(selectAuth);

  // User visits unauthorized page with an accessToken?
  // Let's send them to main and validate auth before YEEEEET'IN 'EM
  if (authState.accessToken) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  return (
    <Box data-testid={ELEMENT_DATA_TEST_IDS.UNAUTHORIZED_PAGE}>
      <Alert
        status={'error'}
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          This page requires authentication.
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          We are unable to authenticate this request. Your session may have expired. Please login again.
        </AlertDescription>
      </Alert>
    </Box>
  );
};

export default UnauthorizedPage;
