import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaArrowLeft, FaCheckCircle, FaCog, FaEdit, FaExternalLinkAlt, FaPlus } from 'react-icons/fa';

import { ViewEditorDrawerMode } from '@/constants/enums';

import styles from './styles.module.scss';

interface ActionDialogProps {
  handleClose: () => void;
  handleCreateView: () => void;
  handleEditView: () => void;
  handleOpenViewer: () => void;
  handleViewAccess: () => void;
  handleViewsList: () => void;
  mode: ViewEditorDrawerMode;
  viewName: string;
  isOpen?: boolean;
}

const getStyledViewName = (viewName: string) => (
  <div className={styles.name}>&nbsp;{viewName}&nbsp;</div>
);

const ActionDialog = (props: ActionDialogProps): React.JSX.Element => {
  const {
    handleClose,
    handleCreateView,
    handleEditView,
    handleOpenViewer,
    handleViewAccess,
    handleViewsList,
    isOpen = false,
    mode,
    viewName,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnEsc size="2xl" returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody mt={6} mb={4}>
          <VStack align={'center'} spacing={4} margin="auto">
            <Box>
              <FaCheckCircle className={styles.icon} size="2em" />
            </Box>
            <Box maxW="100%">
              <Text fontSize="lg" align="center">
                <Text as="b">{getStyledViewName(viewName)}</Text> has been successfully{' '}
                {mode === ViewEditorDrawerMode.CREATE ? 'created' : 'updated'}!
              </Text>
              <Text align="center" fontSize="md">
                What{`'`}s next?
              </Text>
            </Box>
            <Box maxW="100%">
              <Button
                width="100%"
                variant="solid"
                size={'md'}
                leftIcon={<FaCog size={20} />}
                colorScheme={'orange'}
                onClick={handleViewAccess}
              >
                Set view access for {getStyledViewName(viewName)}
              </Button>
            </Box>
            <Box maxW="100%">
              <Button
                width="100%"
                variant="outline"
                size={'md'}
                leftIcon={<FaExternalLinkAlt className={styles.button} />}
                onClick={handleOpenViewer}
              >
                Open {getStyledViewName(viewName)} in Viewer
              </Button>
            </Box>
            <Box maxW="100%">
              <Button
                width="100%"
                variant="outline"
                size={'md'}
                leftIcon={<FaEdit size={20} className={styles.button} />}
                onClick={handleEditView}
              >
                Make changes to {getStyledViewName(viewName)}
              </Button>
            </Box>
            <Box margin="auto">
              <Button
                variant="outline"
                size={'md'}
                leftIcon={<FaPlus size={20} className={styles.button} />}
                onClick={handleCreateView}
              >
                Create another view
              </Button>
            </Box>
            <Box margin="auto">
              <Button
                variant="outline"
                size={'md'}
                leftIcon={<FaArrowLeft size={20} className={styles.button} />}
                onClick={handleViewsList}
              >
                Back to views list
              </Button>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActionDialog;
