import React from 'react';

import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import { TemplateSlim } from '@/types/template.types';

interface TemplateDropdownProps {
  templateList: TemplateSlim[];
  isLoading?: boolean;
  isRequired?: boolean;
  selectedIds?: number[];
  templateChangeHandler?: (values: number[]) => void;
  templateListCloseHandler?: (values: number[]) => void;
  templateListOpenHandler?: () => void;
}

const TemplatesDropdown = (props: TemplateDropdownProps): JSX.Element => {
  const {
    templateList,
    isLoading = false,
    isRequired = false,
    selectedIds = [],
    templateChangeHandler,
    templateListCloseHandler,
    templateListOpenHandler,
  } = props;

  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<number[]>(selectedIds);

  const prevTemplateList = React.useRef<string>(JSON.stringify(templateList));

  React.useEffect(() => {
    const nextTemplateList = JSON.stringify(templateList);

    if (prevTemplateList.current !== nextTemplateList) {
      prevTemplateList.current = nextTemplateList;

      const nextSelectedIds = selectedIds.filter((id) => templateList.some((template) => template.templateId === id));

      if (templateChangeHandler) {
        templateChangeHandler(nextSelectedIds);
      }

      setSelectedTemplateIds(nextSelectedIds);
    }
  }, [selectedIds, templateChangeHandler, templateList]);

  const handleTemplatesChange = (values: FilterSelectValue[]) => {
    const templateIds = values.map(({ value }) => value);

    if (templateChangeHandler) {
      templateChangeHandler(templateIds);
    }

    setSelectedTemplateIds(templateIds);
  };

  const handleTemplateListClose = () => {
    if (templateListCloseHandler) {
      templateListCloseHandler(selectedTemplateIds);
    }
  };

  const isSingleTemplateSelected = React.useMemo(() => {
    // eslint-disable-next-line no-magic-numbers
    return selectedIds.length === 1 || selectedTemplateIds.length === 1
  }, [selectedIds.length, selectedTemplateIds.length])

  return (
    <FilterSelect
      hasSelectAll={true}
      header={'Templates'}
      inputName={'templates'}
      isLoading={isLoading}
      isMultiSelect={true}
      isSingleTemplateSelected={isSingleTemplateSelected}
      isRequired={isRequired}
      onChangeHandler={(values) => handleTemplatesChange(values as FilterSelectValue[])}
      onCloseHandler={handleTemplateListClose}
      onOpenHandler={templateListOpenHandler}
      options={templateList}
      optionLabelKey={'templateName'}
      optionValueKey={'templateId'}
      placeHolderText={'Select Template(s)'}
      selectedValues={selectedTemplateIds}
    />
  );
};

export default TemplatesDropdown;
