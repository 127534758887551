import { createSlice } from '@reduxjs/toolkit';

import { PublicViewsListItem } from '@/API/views.api';
import View from '@/types/view.types';

interface ViewPageState {
  isActionDialogOpen: boolean;
  isCloneViewModalOpen: boolean;
  isDeleteViewModalOpen: boolean;
  isDepartmentDropdownOpen: boolean;
  isRequestViewAccessModalOpen: boolean;
  isTemplateDropdownOpen: boolean;
  isViewAccessDrawerOpen: boolean;
  publicViewsData: PublicViewsListItem[];
  selectedDepartmentIds: number[];
  selectedTemplateIds: number[];
  selectedViewData: View | undefined;
  selectedViewId: number;
  showActionDialog: boolean;
  viewNameSearchFilter: string;
  viewNameSearchValue: string;
}

const initialState: ViewPageState = {
  isActionDialogOpen: false,
  isCloneViewModalOpen: false,
  isDeleteViewModalOpen: false,
  isDepartmentDropdownOpen: false,
  isRequestViewAccessModalOpen: false,
  isTemplateDropdownOpen: false,
  isViewAccessDrawerOpen: false,
  publicViewsData: [],
  selectedDepartmentIds: [],
  selectedTemplateIds: [],
  selectedViewData: undefined,
  selectedViewId: NaN,
  showActionDialog: false,
  viewNameSearchFilter: '',
  viewNameSearchValue: '',
};

const viewPageSlice = createSlice({
  initialState,
  name: 'viewPage',
  reducers: {
    setViewPageCloneViewModalOpen: (state, action) => {
      state.isCloneViewModalOpen = action.payload;
    },
    setViewPageDeleteViewModalOpen: (state, action) => {
      state.isDeleteViewModalOpen = action.payload;
    },
    setViewPageDepartmentDropdownOpen: (state, action) => {
      state.isDepartmentDropdownOpen = action.payload;
    },
    setViewPageIsActionDialogOpen: (state, action) => {
      state.isActionDialogOpen = action.payload;
    },
    setViewPagePublicViewsData: (state, action) => {
      state.publicViewsData = action.payload;
    },
    setViewPageRequestViewAccessModalOpen: (state, action) => {
      state.isRequestViewAccessModalOpen = action.payload;
    },

    setViewPageSelectedDepartmentIds: (state, action) => {
      state.selectedDepartmentIds = action.payload;
    },
    setViewPageSelectedTemplateIds: (state, action) => {
      state.selectedTemplateIds = action.payload;
    },
    setViewPageSelectedViewData: (state, action) => {
      state.selectedViewData = action.payload;
    },
    setViewPageSelectedViewId: (state, action) => {
      state.selectedViewId = action.payload;
    },
    setViewPageTemplateDropdownOpen: (state, action) => {
      state.isTemplateDropdownOpen = action.payload;
    },
    setViewPageViewAccessDrawerOpen: (state, action) => {
      state.isViewAccessDrawerOpen = action.payload;
    },
    setViewPageViewNameSearchFilter: (state, action) => {
      state.viewNameSearchFilter = action.payload;
    },
    setViewPageViewNameSearchValue: (state, action) => {
      state.viewNameSearchValue = action.payload;
    },
    viewPageResetSelectedView: (state) => {
      state.selectedViewData = undefined;
      state.selectedViewId = NaN;
    },
  },
});

export const {
  setViewPageCloneViewModalOpen,
  setViewPageDeleteViewModalOpen,
  setViewPageDepartmentDropdownOpen,
  setViewPageIsActionDialogOpen,
  setViewPagePublicViewsData,
  setViewPageRequestViewAccessModalOpen,
  setViewPageViewNameSearchFilter,
  setViewPageViewNameSearchValue,
  setViewPageSelectedDepartmentIds,
  setViewPageSelectedTemplateIds,
  setViewPageSelectedViewData,
  setViewPageSelectedViewId,
  setViewPageTemplateDropdownOpen,
  setViewPageViewAccessDrawerOpen,
  viewPageResetSelectedView,
} = viewPageSlice.actions;

export default viewPageSlice.reducer;
