import React from 'react';

import { useGetDepartmentsQuery } from '@/API/departments.api';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { initializeDepartmentsAndTemplates } from '@/store/slices/departmentsAndTemplates.slice';
import { DepartmentSlim } from '@/types/department.types';
import { TemplateSlim } from '@/types/template.types';

const useDepartments = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { data } = useGetDepartmentsQuery(undefined, {
    skip: !isAuthenticated,
  });

  const dispatch = useAppDispatch();

  return React.useCallback(() => {
    if (!isAuthenticated) return;

    const departmentIdTemplateMap: { [key: number]: number[] } = {};

    const departments: DepartmentSlim[] = [];
    const templates: Map<number, TemplateSlim> = new Map();

    data?.forEach((department) => {
      departments.push({ departmentId: department.department_id, departmentName: department.name });
      department.templates.forEach((template) => {
        if (!departmentIdTemplateMap?.[department.department_id]) {
          departmentIdTemplateMap[department.department_id] = [template.template_id];
        } else {
          const currentDepartmentTemplates = departmentIdTemplateMap?.[department.department_id] ?? [];

          departmentIdTemplateMap[department.department_id] = [
            ...currentDepartmentTemplates,
            template.template_id,
          ];
        }

        if (!templates.has(template.template_id)) {
          templates.set(template.template_id, {
            templateId: template.template_id,
            templateName: template.name,
          });
        }
      });
    });

    dispatch(initializeDepartmentsAndTemplates({
      departmentIdTemplateMap,
      departments,
      templates: Array.from(templates.values()),
    }));
  }, [data, dispatch, isAuthenticated]);
};

export default useDepartments;
