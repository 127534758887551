import { AccessorFnColumnDef, CellContext, ColumnFiltersState, Table, VisibilityState } from '@tanstack/react-table';
import { Draft, WritableDraft } from 'immer';
import _ from 'lodash';
import React from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { createUseStyles } from 'react-jss';
import { useImmer } from 'use-immer';

import { cellFactory } from '@/components/available-preview-list/aplUtils';
import PaginatedPreviewTable from '@/components/paginated-preview-table/PaginatedPreviewTable';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import { TableOrderDesc } from '@/constants/enums';
import { APLItem, CellColor, HeaderSortHandlers } from '@/types/ui.types';
import SortingUtils from '@/utils/sorting';

enum ColumnFilterStateProperty {
  AvailableItemsColumnFilters = 'availableItemsColumnFilters',
  PreviewItemsColumnFilters = 'previewItemsColumnFilters',
}

type TableColumnIds = { [key: string]: string };

type ItemMap<T> = Map<number, APLItem<T>>;

interface ITableState<T> {
  availableItems: ItemMap<T>;
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  availableItemsColumnFilters: ColumnFiltersState;
  highlightAllIsToggled: boolean;
  highlightedPreviewRowIds: Set<number>;
  isLoading: boolean;
  previewColumns: AccessorFnColumnDef<APLItem<T>>[];
  previewItems: ItemMap<T>;
  previewItemsColumnFilters: ColumnFiltersState;
  selectAllAvailableIsToggled: boolean;
  selectAllPreviewIsToggled: boolean;
  // selectedPreviewRowViewIds: Set<number>;
  tableOrderDesc: TableOrderDesc;
  unfilteredViewIds: number[];
}

interface IAvailablePreviewListProps<T> {
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly availableColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  readonly availableItems: ItemMap<T>;
  readonly availableItemsPrimaryColumnId: string;
  readonly availableItemsTableLabel: string;
  readonly availableRowUniqueKey: string;
  // readonly currentlySelectedItemIds: number[];
  readonly isOpen: boolean;
  readonly onItemsChanged: (updatedData: T[]) => void;
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly previewColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  readonly previewItems: ItemMap<T>;
  readonly previewItemsPrimaryColumnId: string;
  readonly previewItemsTableLabel: string;
  readonly previewRowUniqueKey: string;
  readonly primaryAvailableColumnIndex: number;
  readonly primaryPreviewColumnIndex: number;
  readonly tableColumnIds: TableColumnIds;
  readonly availableItemsColumnFilters?: ColumnFiltersState;
  readonly availableColumnsVisibility?: VisibilityState;
  readonly availableItemsTableNoDataFoundMessage?: string;
  readonly cellContentInjector?: (item: CellContext<APLItem<T>, unknown>) => React.JSX.Element;
  readonly injectedContentOverridesText?: boolean;
  readonly onSortingStateChanged?: (sortingState: TableOrderDesc) => void;
  readonly overrideSorting?: boolean;
  readonly previewColumnsVisibility?: VisibilityState;
  readonly previewHeaderSortHandlers?: HeaderSortHandlers;
  readonly previewRowsAreDraggable?: boolean;
  // readonly previewTableDragHandler?: (updatedData: unknown[]) => void;
  readonly showColorPicker?: boolean;
  readonly showSideControls?: boolean;
}

const useSharedRowStyles = createUseStyles({
  row: {
    '&:hover': {
      backgroundColor: '#E2E8F0',
      color: 'white',
    },
    cursor: 'pointer',
  },
});

const usePreviewRowStyle = createUseStyles({
  icon: {
    '&:hover': {
      color: '#d21515',
    },
    color: '#5b5757',
    cursor: 'pointer',
  },
});

const clearColumnFilter = <T,>(
  columnName: keyof TableColumnIds,
  filterStateDraft: WritableDraft<ITableState<T>>,
  columnFilterProperty: ColumnFilterStateProperty,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filterStateDraft[columnFilterProperty.valueOf()] = filterStateDraft[columnFilterProperty.valueOf()].filter(
    (filterValue: { id: string | number }) => filterValue.id !== columnName,
  );
};

const updateColumnFilter = <T,>(
  columnName: keyof TableColumnIds,
  filterStateDraft: WritableDraft<ITableState<T>>,
  columnFilterProperty: ColumnFilterStateProperty,
  filterValue: string | number[],
) => {
  clearColumnFilter(columnName, filterStateDraft, columnFilterProperty);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filterStateDraft[columnFilterProperty.valueOf()].push({
    id: columnName,
    value: filterValue,
  });
};

const AvailablePreviewList = <T,>(props: IAvailablePreviewListProps<T>): React.JSX.Element => {
  const {
    availableColumns,
    availableColumnsVisibility,
    availableItems,
    availableItemsColumnFilters = [],
    availableItemsPrimaryColumnId,
    availableItemsTableLabel,
    availableItemsTableNoDataFoundMessage,
    availableRowUniqueKey,
    cellContentInjector,
    injectedContentOverridesText = false,
    isOpen,
    onItemsChanged,
    onSortingStateChanged,
    overrideSorting = false,
    previewColumns,
    previewColumnsVisibility,
    previewItems,
    previewItemsPrimaryColumnId,
    previewItemsTableLabel,
    previewRowUniqueKey,
    primaryAvailableColumnIndex,
    primaryPreviewColumnIndex,
    previewRowsAreDraggable = false,
    showColorPicker = false,
    showSideControls = false,
    tableColumnIds,
  } = props;

  const [tableState, updateTableState] = useImmer<ITableState<T>>({
    availableColumns: [],
    availableItems: availableItems ?? new Map(),
    availableItemsColumnFilters,
    highlightAllIsToggled: false,
    highlightedPreviewRowIds: new Set<number>(),
    isLoading: false,
    previewColumns: [],
    previewItems: previewItems ?? new Map(),
    previewItemsColumnFilters: [],
    selectAllAvailableIsToggled: false,
    selectAllPreviewIsToggled: false,
    // selectedPreviewRowViewIds: new Set(previewItems.keys()),
    tableOrderDesc: { availableItemsDesc: false, previewItemsDesc: false },
    unfilteredViewIds: [],
  });

  const prevPreviewItemsDesc = React.useRef(tableState.tableOrderDesc.previewItemsDesc);

  // These refs are used to store the table instances for the available and preview items tables
  // React Table is handling the filtering, so we can just sneakily grab the filtered rows from the table instance
  // instead of managing the filtered rows ourselves
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const availableItemsTableRef = React.useRef<Table<any> | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const previewItemsTableRef = React.useRef<Table<any> | undefined>();

  const prevPreviewItems = React.useRef(JSON.stringify([...previewItems.values()]));
  const prevAvailableItemKeys = React.useRef([...availableItems.keys()]);

  const prevAvailableItemsColumnFilters = React.useRef(availableItemsColumnFilters);

  const rowClasses = useSharedRowStyles();
  const previewClasses = usePreviewRowStyle();

  const handleAvailableItemClick = React.useCallback(
    (id: number) => {
      updateTableState((draft) => {
        const view = draft.availableItems.get(id);

        if (!view) return draft;

        const updatedAvailableItems = new Map(draft.availableItems);
        updatedAvailableItems.delete(id);

        const updatedPreviewItems = new Map(draft.previewItems);
        updatedPreviewItems.set(id, view);

        draft.availableItems = updatedAvailableItems;
        draft.previewItems = updatedPreviewItems;
      });
    },
    [updateTableState],
  );

  const handlePreviewItemClick = React.useCallback(
    (id: number) => {
      updateTableState((draft) => {
        const item = draft.previewItems.get(id);

        if (!item) return;

        item.color = null;
        item.colorText = null;

        draft.highlightedPreviewRowIds.delete(id);

        draft.previewItems.delete(id);
        draft.availableItems.set(id, item);
      });
    },
    [updateTableState],
  );

  const handleHighlightAll = React.useCallback(() => {
    // Handle highlighting all preview items
    if (!tableState.highlightedPreviewRowIds.size) {
      if (tableState.previewItemsColumnFilters.length && previewItemsTableRef?.current) {
        const matchingRows = previewItemsTableRef.current.getFilteredRowModel()?.rowsById;

        updateTableState((draft) => {
          for (const key of Object.keys(matchingRows)) {
            const id = parseInt(key);

            draft.highlightedPreviewRowIds.add(id);
          }

          draft.highlightAllIsToggled = true;
        });
      } else {
        // If we do not have any filters, highlight all preview items
        updateTableState((draft) => {
          for (const id of draft.previewItems.keys()) {
            draft.highlightedPreviewRowIds.add(id);
          }

          draft.highlightAllIsToggled = true;
        });
      }
    } else {
      // Only clear highlighted items matching the filters
      if (tableState.previewItemsColumnFilters.length && previewItemsTableRef?.current) {
        const matchingRows = previewItemsTableRef.current.getFilteredRowModel()?.rowsById;

        updateTableState((draft) => {
          for (const key of Object.keys(matchingRows)) {
            const id = parseInt(key);

            if (draft.highlightedPreviewRowIds.has(id)) {
              draft.highlightedPreviewRowIds.delete(id);
            }
          }
        });
      } else {
        // We do not have any filters, so clear all highlighted items
        updateTableState((draft) => {
          draft.highlightedPreviewRowIds.clear();
          draft.highlightAllIsToggled = false;
        });
      }
    }
  }, [tableState.highlightedPreviewRowIds.size, tableState.previewItemsColumnFilters.length, updateTableState]);

  // Handle selecting (clearing) all preview items
  const handleSelectAllPreviewItems = React.useCallback(() => {
    // If there are available items
    if (tableState.previewItems.size) {
      // If we have filters, only move items that match the filters
      if (tableState.previewItemsColumnFilters.length && previewItemsTableRef?.current) {
        const matchingRows = previewItemsTableRef.current.getFilteredRowModel()?.rowsById;

        updateTableState((draft) => {
          for (const key of Object.keys(matchingRows)) {
            const id = parseInt(key);
            const item = { ...tableState.previewItems.get(id) };

            if (!item) return;

            draft.availableItems.set(id, item as Draft<APLItem<T>>);
            draft.highlightedPreviewRowIds.delete(id);
            draft.previewItems.delete(id);
          }
        });
      } else {
        // If we don't have filters, move all available items to preview items
        updateTableState((draft) => {
          for (const [k, v] of draft.previewItems.entries()) {
            draft.availableItems.set(k, v);
          }

          draft.highlightedPreviewRowIds.clear();
          draft.previewItems.clear();
        });
      }
    }
  }, [tableState.previewItems, tableState.previewItemsColumnFilters.length, updateTableState]);

  React.useEffect(() => {
    if (onSortingStateChanged) {
      onSortingStateChanged(tableState.tableOrderDesc);
    }
  }, [onSortingStateChanged, tableState.tableOrderDesc]);

  const handleSelectAllAvailableItems = React.useCallback(() => {
    // If there are available items
    if (tableState.availableItems.size) {
      // If we have filters, only move items that match the filters
      if (tableState.availableItemsColumnFilters.length && availableItemsTableRef?.current) {
        const matchingRows = availableItemsTableRef.current.getFilteredRowModel()?.rowsById;

        updateTableState((draft) => {
          for (const key of Object.keys(matchingRows)) {
            const id = parseInt(key);
            const item = { ...tableState.availableItems.get(id) };

            if (!item) return;

            draft.previewItems.set(id, item as Draft<APLItem<T>>);
            draft.availableItems.delete(id);
          }
        });
      } else {
        // If we don't have filters, move all available items to preview items
        updateTableState((draft) => {
          for (const [k, v] of draft.availableItems.entries()) {
            draft.previewItems.set(k, v);
          }

          draft.availableItems.clear();
        });
      }
    }
  }, [tableState.availableItems, tableState.availableItemsColumnFilters, updateTableState]);

  React.useEffect(() => {
    if (_.isEmpty(availableItemsColumnFilters) && _.isEmpty(tableState.availableItemsColumnFilters)) return;

    if (_.isEqual(availableItemsColumnFilters, prevAvailableItemsColumnFilters.current)) return;

    prevAvailableItemsColumnFilters.current = availableItemsColumnFilters;

    updateTableState((draft) => {
      draft.availableItemsColumnFilters = availableItemsColumnFilters;
    });
  }, [availableItemsColumnFilters, tableState.availableItemsColumnFilters, updateTableState]);

  // Available views select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllAvailableItems}
        isDisabled={!tableState.availableItems.size}
        selectAllIsToggled={tableState.selectAllAvailableIsToggled}
      />
    );
  };

  // Available views select all button on the table header
  const getPreviewItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleHighlightAll={handleHighlightAll}
        handleSelectAll={handleSelectAllPreviewItems}
        highlightAllIsToggled={!!tableState.highlightedPreviewRowIds.size}
        invertSelectAll={true}
        isDisabled={!tableState.previewItems.size}
        selectAllIsToggled={tableState.selectAllPreviewIsToggled}
        showHighlightAll={true}
      />
    );
  };

  // Selected views search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader
        handleFilter={(e) => {
          updateTableState((draft) => {
            updateColumnFilter(
              tableColumnIds[previewItemsPrimaryColumnId],
              draft,
              ColumnFilterStateProperty.PreviewItemsColumnFilters,
              e.target.value,
            );
          });
        }}
        label={previewItemsTableLabel}
        placeholder="Search Selected"
      />
    );
  };

  // Available views search bar
  const getAvailableItemsTableHeader = () => {
    return (
      <TableHeader
        handleFilter={(e) => {
          updateTableState((draft) => {
            updateColumnFilter(
              tableColumnIds[availableItemsPrimaryColumnId],
              draft,
              ColumnFilterStateProperty.AvailableItemsColumnFilters,
              e.target.value,
            );
          });
        }}
        label={availableItemsTableLabel}
        placeholder={'Search Available'}
      />
    );
  };

  const selectedPreviewItemIds = React.useMemo(() => {
    return [...tableState.previewItems.keys()];
  }, [tableState]);

  const availableItemIds = React.useMemo(() => {
    return [...tableState.availableItems.keys()];
  }, [tableState]);

  const handlePreviewRowClick = React.useCallback(
    (id: number) => {
      updateTableState((draft) => {
        const updatedHighlightedPreviewRowIds = new Set(draft.highlightedPreviewRowIds);

        if (updatedHighlightedPreviewRowIds.has(id)) {
          updatedHighlightedPreviewRowIds.delete(id);
        } else {
          updatedHighlightedPreviewRowIds.add(id);
        }

        draft.highlightedPreviewRowIds = updatedHighlightedPreviewRowIds;
      });
    },
    [updateTableState],
  );

  React.useEffect(() => {
    const updatedItems = JSON.stringify([...tableState.previewItems.values()]);

    if (prevPreviewItems.current === updatedItems) return;

    prevPreviewItems.current = updatedItems;

    onItemsChanged([...tableState.previewItems.values()]);
  }, [onItemsChanged, selectedPreviewItemIds, tableState.previewItems, tableState.availableItems, availableItemIds]);

  React.useEffect(() => {
    updateTableState((draft) => {
      const currentColumns = [...availableColumns];

      const currentPrimaryColumnCell = (item: CellContext<APLItem<T>, unknown>) =>
        cellFactory<APLItem<T>>({
          cellContentInjector,
          cellContext: item,
          className: rowClasses.row,
          clickHandler: handleAvailableItemClick,
          injectedContentOverridesText,
          text: item.row.original.name,
        });
      currentColumns[primaryAvailableColumnIndex] = {
        ...currentColumns[primaryAvailableColumnIndex],
        cell: currentPrimaryColumnCell,
      };

      draft.availableColumns = currentColumns;
    });
  }, [
    availableColumns,
    cellContentInjector,
    handleAvailableItemClick,
    injectedContentOverridesText,
    primaryAvailableColumnIndex,
    rowClasses.row,
    updateTableState,
  ]);

  React.useEffect(() => {
    updateTableState((draft) => {
      const currentColumns = [...previewColumns];

      const currentPrimaryColumnCell = (item: CellContext<APLItem<T>, unknown>) =>
        cellFactory<APLItem<T>>({
          actionIcon: <FaTrashCan className={previewClasses.icon} />,
          actionIconClickHandler: handlePreviewItemClick,
          cellContentInjector,
          cellContext: item,
          className: rowClasses.row,
          clickHandler: handlePreviewRowClick,
          injectedContentOverridesText,
          isSelected: tableState.highlightedPreviewRowIds.has(item.row.original.id),
          text: item.row.original.name,
        });
      currentColumns[primaryPreviewColumnIndex] = {
        ...currentColumns[primaryPreviewColumnIndex],
        cell: currentPrimaryColumnCell,
      };

      draft.previewColumns = currentColumns;
    });
  }, [
    cellContentInjector,
    handlePreviewItemClick,
    handlePreviewRowClick,
    injectedContentOverridesText,
    previewClasses.icon,
    previewColumns,
    primaryPreviewColumnIndex,
    rowClasses.row,
    tableState.highlightedPreviewRowIds,
    updateTableState,
  ]);

  // If we received new available items, update the table state, otherwise skip
  React.useEffect(() => {
    const updatedItemKeys = [...availableItems.keys()];

    if (_.isEqual(prevAvailableItemKeys.current, updatedItemKeys)) return;

    prevAvailableItemKeys.current = updatedItemKeys;

    updateTableState((draft) => {
      draft.availableItems = availableItems as Draft<ItemMap<T>>;
    });
  }, [availableItems, updateTableState]);

  // If we received new preview items, update the table state, otherwise skip
  // ToDo: Change this to use keys
  React.useEffect(() => {
    const updatedItems = JSON.stringify([...previewItems.values()]);

    if (prevPreviewItems.current === updatedItems) return;

    prevPreviewItems.current = updatedItems;

    updateTableState((draft) => {
      draft.previewItems = previewItems as Draft<ItemMap<T>>;
    });
  }, [previewItems, updateTableState]);

  React.useEffect(() => {
    if (prevPreviewItemsDesc.current !== tableState.tableOrderDesc.previewItemsDesc) {
      prevPreviewItemsDesc.current = tableState.tableOrderDesc.previewItemsDesc;
    } else {
      return;
    }

    if (overrideSorting) {
      const sortedPreviewItems = [...tableState.previewItems.values()].sort((a, b) => {
        // eslint-disable-next-line no-magic-numbers
        return SortingUtils.sortStrings(a['name'], b['name']) * (tableState.tableOrderDesc.previewItemsDesc ? -1 : 1);
      });

      onItemsChanged?.(sortedPreviewItems);
    }
  }, [overrideSorting, onItemsChanged, tableState.previewItems, tableState.tableOrderDesc.previewItemsDesc]);

  const handleSetCellColors = React.useCallback(
    (colors: CellColor) => {
      if (!colors || !tableState.highlightedPreviewRowIds.size) return;

      const updatedPreviewItems = new Map(tableState.previewItems);

      tableState.highlightedPreviewRowIds.forEach((id) => {
        const item = updatedPreviewItems.get(id);

        if (!item) return;

        updatedPreviewItems.set(id, {
          ...item,
          color: colors.cellColor,
          colorText: colors.textColor,
        });
      });

      onItemsChanged?.([...updatedPreviewItems.values()]);
    },
    [onItemsChanged, tableState.highlightedPreviewRowIds, tableState.previewItems],
  );

  const handleDrag = React.useCallback(
    (result: unknown) => {
      updateTableState((draft) => {
        const updatedItems = new Map((result as APLItem<T>[]).map((item: APLItem<T>) => [item.id, item]));

        draft.previewItems = updatedItems as Draft<ItemMap<T>>;
      });
    },
    [updateTableState],
  );

  const selectedPreviewItems = React.useMemo(() => {
    return [...tableState.previewItems.values()].filter((item) => tableState.highlightedPreviewRowIds.has(item.id));
  }, [tableState.highlightedPreviewRowIds, tableState.previewItems]);

  if (!isOpen) return <></>;

  return (
    <PaginatedPreviewTable
      availableItemsTableColumnFilters={[...tableState.availableItemsColumnFilters, ...availableItemsColumnFilters]}
      availableItemsTableColumns={tableState.availableColumns}
      // ToDo: Lift out to props
      availableItemsTableColumnVisibility={availableColumnsVisibility}
      availableItemsTableData={[...tableState.availableItems.values()]}
      availableItemsTableHeader={getAvailableItemsTableHeader()}
      availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
      availableItemsTableInstanceRef={availableItemsTableRef}
      availableItemsTableNoDataFoundMessage={availableItemsTableNoDataFoundMessage}
      availableItemsTableSortingState={[
        {
          desc: tableState.tableOrderDesc.availableItemsDesc,
          id: tableColumnIds[availableItemsPrimaryColumnId],
        },
      ]}
      // ToDo: Correct prop in PersonnelViewAccess
      availableRowUniqueKey={availableRowUniqueKey}
      handleSetCellColors={handleSetCellColors}
      headerSortHandlers={[
        {
          columnId: tableColumnIds[availableItemsPrimaryColumnId],
          sortHandler: () =>
            updateTableState((draft) => {
              draft.tableOrderDesc = {
                ...draft.tableOrderDesc,
                availableItemsDesc: !draft.tableOrderDesc.availableItemsDesc,
              };
            }),
        },
      ]}
      // ToDo: pass via props
      isLoading={false}
      overrideTableSorting={overrideSorting}
      previewHeaderSortHandlers={[
        {
          columnId: tableColumnIds[previewItemsPrimaryColumnId],
          sortHandler: () =>
            updateTableState((draft) => {
              draft.tableOrderDesc = {
                ...draft.tableOrderDesc,
                previewItemsDesc: !draft.tableOrderDesc.previewItemsDesc,
              };
            }),
        },
      ]}
      previewRowsAreDraggable={previewRowsAreDraggable}
      previewRowUniqueKey={previewRowUniqueKey}
      previewTableColumnFilters={tableState.previewItemsColumnFilters}
      previewTableColumns={tableState.previewColumns}
      // ToDo: Lift out to props
      previewTableColumnVisibility={previewColumnsVisibility}
      previewTableData={[...tableState.previewItems.values()]}
      previewTableDragHandler={handleDrag}
      previewTableHeader={getPreviewTableHeader()}
      previewTableHeaderButton={getPreviewItemsTableHeaderButton()}
      previewItemsTableInstanceRef={previewItemsTableRef}
      previewTableSortingState={[
        {
          desc: tableState.tableOrderDesc.previewItemsDesc,
          id: tableColumnIds[previewItemsPrimaryColumnId],
        },
      ]}
      previewTableUniqueSelectedIds={tableState.highlightedPreviewRowIds}
      selectedPreviewItems={selectedPreviewItems}
      showColorPicker={showColorPicker}
      showSideControls={showSideControls}
    />
  );
};

export default AvailablePreviewList;
