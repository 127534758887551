import { FormLabel, Select, VStack } from '@chakra-ui/react';
import React from 'react';

interface CustomDropdownProps {
  label: string;
  options: string[] | number[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedOption: string | number;
  width?: string;
}

const CustomDropdown = (props: CustomDropdownProps) => {
  const { label, options, onChange, selectedOption, width = '200px' } = props;

  return (
    <VStack justifyContent={'space-between'} align={'left'}>
      <FormLabel>{label}</FormLabel>
      <Select size={'md'} w={width} onChange={onChange} value={selectedOption}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </VStack>
  );
};

export default CustomDropdown;
