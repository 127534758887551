import { Box, Spinner, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router';

import { ROUTES } from '@/constants/config';
import { useAppSelector } from '@/store/hooks';
import { selectAuth } from '@/store/slices/auth.slice';

const ProtectedRoute = ({ children }: PropsWithChildren): React.JSX.Element => {
  const authState = useAppSelector(selectAuth, _.isEqual);

  return React.useMemo(() => {
    if (authState.isPending) {
      return (
        <Box>
          <Text>Loading</Text>
          <Spinner />
        </Box>
      );
    }

    return authState.accessToken ? <>{children}</> : <Navigate to={ROUTES.UNAUTHORIZED} />;
  }, [authState, children]);
};

export default ProtectedRoute;
