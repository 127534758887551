import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store/store';
import View from '@/types/view.types';

interface ViewAccess {
  selectedDepartmentIds: number[];
  selectedPersonnelIds: number[];
  selectedPersonnelTypeIds: number[];
  publicURL: string;
}

interface TargetView extends View {
  access: ViewAccess;
}

interface ViewsState {
  views: View[];
  targetView: TargetView | undefined;
}

const initialState: ViewsState = { targetView: undefined, views: [] };

const defaultViewAccess: ViewAccess = {
  publicURL: '',
  selectedDepartmentIds: [],
  selectedPersonnelIds: [],
  selectedPersonnelTypeIds: [],
};

export const viewsSlice = createSlice({
  initialState,
  name: 'viewsCollection',
  reducers: {
    hydrateViewsCollection: (state, action) => {
      state.views = action.payload;
    },
    selectPersonnelForTargetView: (state, action) => {
      if (state.targetView) {
        if (Array.isArray(action.payload)) {
          state.targetView.access.selectedPersonnelIds = action.payload;
          return;
        }

        const personnelId: number = action.payload;
        const selectedPersonnelIds = state.targetView.access.selectedPersonnelIds;

        if (!selectedPersonnelIds.includes(personnelId)) {
          selectedPersonnelIds.push(personnelId);
        } else if (selectedPersonnelIds.includes(personnelId)) {
          const personnelIndex = selectedPersonnelIds.indexOf(personnelId);
          // eslint-disable-next-line no-magic-numbers
          selectedPersonnelIds.splice(personnelIndex, 1);
        }
      }
    },
    setPublicURLForTargetView: (state, action) => {
      if (state.targetView) {
        let targetViewIdx = null;
        state.views.forEach((view, idx) => {
          if (view.viewId === state.targetView?.viewId) {
            targetViewIdx = idx;
          }
        });

        if (targetViewIdx !== null) {
          state.targetView.access.publicURL = action.payload;
          state.views[targetViewIdx].publicViewId = action.payload;
        }
      }
    },
    setTargetView: (state, action) => {
      const targetViewId = action.payload;
      const targetView = state.views.find((view) => view.viewId === targetViewId);

      if (targetView) {
        state.targetView = {
          ...targetView,
          access: { ...defaultViewAccess, publicURL: targetView.publicViewId ?? '' },
        };

        return;
      }

      state.targetView = undefined;
    },
    setTargetViewAccessSelectedDepartmentIds: (state, action) => {
      if (state.targetView) {
        state.targetView.access.selectedDepartmentIds = action.payload;
      }
    },
    setTargetViewAccessSelectedPersonnelIds: (state, action) => {
      if (state.targetView) {
        state.targetView.access.selectedPersonnelIds = action.payload;
      }
    },
    setTargetViewAccessSelectedPersonnelTypeIds: (state, action) => {
      if (state.targetView) {
        state.targetView.access.selectedPersonnelTypeIds = action.payload;
      }
    },
  },
});

export const selectViews = (state: RootState) => state.viewsCollection.views;
export const selectTargetView = (state: RootState) => state.viewsCollection.targetView;

export const selectViewWithName = (state: RootState) => (viewName: string) =>
  state.viewsCollection.views.find((view) => view.name === viewName);

// export const selectViewWithId = (state: RootState, viewId: number) =>
//   state.viewsCollection.views.find((view) => view.id === viewId);

export default viewsSlice.reducer;
