export enum SORTING_ORDER {
    ASC = 'asc',
    DESC = 'desc',
    NONE = 'none',
  }

  export enum ORDER_BY {
    ViewName = 'ViewName',
  }

const UIConstants = {
  ORDER_BY,
  SORTING_ORDER,
};

export default UIConstants;
  