import { Box, IconButton, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaAngleDoubleDown, FaAngleDoubleUp, FaAngleDown, FaAngleUp, FaPaintBrush } from 'react-icons/fa';

import { DEFAULT_CELL_COLOR_OPTIONS } from '@/constants/defaults';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import { CellColor } from '@/types/ui.types';

import styles from './styles.module.scss';

const COLOR_PICKER_LABEL = 'Color picker';
const MOVE_TOP_LABEL = 'Move selection to top';
const MOVE_UP_LABEL = 'Move selection up';
const MOVE_DOWN_LABEL = 'Move selection down';
const MOVE_BOTTOM_LABEL = 'Move selection to the bottom';

interface SideControlsProps {
  onColorPickerClick: () => void;
  onMoveTopClick: () => void;
  onMoveUpClick: () => void;
  onMoveDownClick: () => void;
  onMoveBottomClick: () => void;
  onQuickColorPickerClick: (color: CellColor) => void;
  colorPickerDisabled?: boolean;
  showColorPicker?: boolean;
  sortingDisabled?: boolean;
}

const SideControls = (props: SideControlsProps): React.JSX.Element => {
  const [quickPickerOpen, setQuickPickerOpen] = useState<boolean>(false);

  const {
    colorPickerDisabled,
    showColorPicker = true,
    sortingDisabled,
    onColorPickerClick,
    onMoveTopClick,
    onMoveUpClick,
    onMoveDownClick,
    onMoveBottomClick,
    onQuickColorPickerClick,
  } = props;

  const handleColorClick = (color: CellColor) => {
    onQuickColorPickerClick(color);
    setQuickPickerOpen(false);
  };

  const getColorPicker = (): React.JSX.Element => {
    return (
      <>
        <IconButton
          isDisabled={colorPickerDisabled}
          className={styles.icon}
          isRound
          variant="ghost"
          size="md"
          aria-label={COLOR_PICKER_LABEL}
          icon={<FaPaintBrush />}
          mb={2}
          color={'blue.400'}
          onClick={onColorPickerClick}
          onMouseEnter={() => setQuickPickerOpen(true)}
          onMouseLeave={() => setQuickPickerOpen(false)}
          data-test-id={ELEMENT_DATA_TEST_IDS.COLOR_PICKER_BUTTON}
        />

        <VStack
          className={styles.picker}
          gap={2}
          display={quickPickerOpen ? 'flex' : 'none'}
          onMouseEnter={() => setQuickPickerOpen(true)}
          onMouseLeave={() => setQuickPickerOpen(false)}
        >
          {DEFAULT_CELL_COLOR_OPTIONS.map((color) => (
            <Box
              key={color.cellColor}
              backgroundColor={color.cellColor}
              color={color.textColor}
              className={styles.cell}
              onClick={() => handleColorClick(color)}
            >
              <Text fontWeight={500} align="center">
                A
              </Text>
            </Box>
          ))}
        </VStack>
      </>
    );
  };

  return (
    <VStack align={'center'} className={styles.container}>
      {showColorPicker && getColorPicker()}

      <Tooltip placement="left" label={MOVE_TOP_LABEL} hasArrow isDisabled={sortingDisabled}>
        <IconButton
          isDisabled={sortingDisabled}
          className={styles.icon}
          isRound
          variant="ghost"
          size="md"
          aria-label={MOVE_TOP_LABEL}
          icon={<FaAngleDoubleUp />}
          onClick={onMoveTopClick}
          data-testid={ELEMENT_DATA_TEST_IDS.MOVE_ITEMS_TOP_BUTTON}
        />
      </Tooltip>

      <Tooltip placement="left" label={MOVE_UP_LABEL} hasArrow isDisabled={sortingDisabled}>
        <IconButton
          isDisabled={sortingDisabled}
          className={styles.icon}
          isRound
          variant="ghost"
          size="md"
          aria-label={MOVE_UP_LABEL}
          icon={<FaAngleUp />}
          onClick={onMoveUpClick}
          data-testid={ELEMENT_DATA_TEST_IDS.MOVE_ITEMS_UP_BUTTON}
        />
      </Tooltip>

      <Tooltip placement="left" label={MOVE_DOWN_LABEL} hasArrow isDisabled={sortingDisabled}>
        <IconButton
          isDisabled={sortingDisabled}
          className={styles.icon}
          isRound
          variant="ghost"
          size="md"
          aria-label={MOVE_DOWN_LABEL}
          icon={<FaAngleDown />}
          onClick={onMoveDownClick}
          data-testid={ELEMENT_DATA_TEST_IDS.MOVE_ITEMS_DOWN_BUTTON}
        />
      </Tooltip>

      <Tooltip placement="left" label={MOVE_BOTTOM_LABEL} hasArrow isDisabled={sortingDisabled}>
        <IconButton
          isDisabled={sortingDisabled}
          className={styles.icon}
          isRound
          variant="ghost"
          size="md"
          aria-label={MOVE_BOTTOM_LABEL}
          icon={<FaAngleDoubleDown />}
          onClick={onMoveBottomClick}
          data-testid={ELEMENT_DATA_TEST_IDS.MOVE_ITEMS_BOTTOM_BUTTON}
        />
      </Tooltip>
    </VStack>
  );
};

export default SideControls;
