import { Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchInput = (props: InputProps): React.JSX.Element => {
  const { width = '300px' } = props;

  return (
    <InputGroup>
      <InputLeftElement pointerEvents={'none'} color="gray.400" height="100%">
        <FaSearch />
      </InputLeftElement>
      <Input {...props} width={width} />
    </InputGroup>
  );
};

export default SearchInput;
