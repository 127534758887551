import { ColumnFiltersState } from '@tanstack/react-table';
import { produce } from 'immer';

import { DEFAULT_ARRAY_ELEMENT_DELETE_COUNT, DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND } from '@/constants/defaults';
import { ViewEditorColumnFilterFields } from '@/constants/enums';

export const updateColumnFilter = (id: string, value: unknown, columnState: ColumnFiltersState) => {

  return produce(columnState, (draft) => {
    const filterIdx = draft.findIndex((filter) => filter.id === id);

    // Hide Inactive Assignments radio button uses "expired" property to filter the data
    // When filtering the expired property we need to reverse the logic to hide the inactive(expired) assignment
    if (id === ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS) {
      // In order to hide the inactive(expired) assignment and show only the active ones, the value of the filter should be "false"
      // Otherwise we remove the value and display all the items
      if (value) {
        value = false;
      } else value = undefined;
    }

    if (filterIdx === DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND) {
      draft.push({
        id,
        value,
      });
    } else {
      if (!value) {
        draft.splice(filterIdx, DEFAULT_ARRAY_ELEMENT_DELETE_COUNT);
        return;
      }
      draft[filterIdx] = {
        id,
        value,
      };
    }
  });
};
