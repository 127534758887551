import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthResponse } from '@/API/api.slice';
import { RootState } from '@/store/store';
import { ConfigHelper, ConfigKeys } from '@/utils/env';

export type GrantType = 'password' | 'refresh_token';

type AuthState = {
  accessToken: string | null;
  clientId: string | undefined;
  expiresIn: number | null;
  grantType: GrantType | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isPending: boolean;
  refreshToken: string | null;
  tokenType: string | null;
};

const initialState: AuthState = {
  accessToken: null,
  clientId: ConfigHelper.getConfig(ConfigKeys.CLIENT_ID),
  expiresIn: null,
  grantType: null,
  isAuthenticated: false,
  isLoading: false,
  isPending: true,
  refreshToken: null,
  tokenType: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    resetAuth: (state) => {
      state.accessToken = null;
      state.expiresIn = null;
      state.grantType = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.isPending = true;
      state.refreshToken = null;
      state.tokenType = null;
    },
    revokeAuth: (state) => {
      state.accessToken = null;
      state.expiresIn = null;
      state.tokenType = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.isPending = false;
    },
    setAuth: (state, action: PayloadAction<AuthResponse>) => {
      state.accessToken = action.payload.access_token;
      state.expiresIn = action.payload.expires_in;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.isPending = false;
      state.tokenType = action.payload.token_type;
      state.refreshToken = action.payload.refresh_token;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
  },
});

export const { resetAuth, revokeAuth, setAuth, setIsLoading, setRefreshToken } = authSlice.actions;

export default authSlice.reducer;

export const selectAuth = (state: RootState) => state.auth;
