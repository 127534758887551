import { Button, HStack, useToast, VStack } from '@chakra-ui/react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useDeletePublicViewUrlMutation, useGeneratePublicViewUrlMutation } from '@/API/views.api';
import { DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewAccessPublicViewURL,
  setViewAccessShowDeletePublicURLConfirmationDialog,
} from '@/store/slices/viewAccess.slice';
import { ApiError } from '@/types/api.types';
import { getLBPublicViewUrl } from '@/utils/url';

import ConfirmationDialog from '../confirmation-dialog/ConfirmationDialog';

const PUBLIC_URL_COPY_SUCCESS_MESSAGE = 'Public URL copied successfully.';
const PUBLIC_URL_COPY_ERROR_MESSAGE = 'An error occurred when trying to copy Public URL.';
const PUBLIC_URL_GENERATE_SUCCESS_MESSAGE = 'Public URL generated successfully.';
const PUBLIC_URL_GENERATE_ERROR_MESSAGE = 'An error occurred when trying to generate Public URL.';
const PUBLIC_URL_DELETED_SUCCESS_MESSAGE = 'Public URL deleted successfully.';
const PUBLIC_URL_DELETED_ERROR_MESSAGE = 'Public URL could not be deleted.';

const PublicURLControls = (): React.JSX.Element => {
  const { selectedViewData: view } = useAppSelector((state) => state.viewPage);
  const { isLoading, publicViewURL, showDeletePublicURLConfirmationDialog } = useAppSelector(
    (state) => state.viewAccess,
  );

  const [generatePublicURL, generatePublicURLResponse] = useGeneratePublicViewUrlMutation();
  const [deletePublicURL, deletePublicURLResponse] = useDeletePublicViewUrlMutation();

  const toast = useToast();

  const dispatch = useAppDispatch();

  const publicViewURLValue = React.useMemo(() => {
    if (!publicViewURL) return undefined;

    return getLBPublicViewUrl(publicViewURL);
  }, [publicViewURL]);

  if (!view) return <></>;

  const handleGeneratePublicURL = async () => {
    try {
      const response = await generatePublicURL({ name: view.name, viewId: view.viewId }).unwrap();

      // Check if the public view was generated successfully
      if (response?.id) {
        dispatch(setViewAccessPublicViewURL(response.id));
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: PUBLIC_URL_GENERATE_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? PUBLIC_URL_GENERATE_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const handleDeletePublicURL = async () => {
    if (!view.publicViewId) return;
    const oldPublicUrl = publicViewURLValue;

    dispatch(setViewAccessPublicViewURL(''));

    try {
      const response = await deletePublicURL(view.publicViewId).unwrap();

      // Check if the public url was deleted successfully
      if (response?.deleted) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: PUBLIC_URL_DELETED_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? PUBLIC_URL_DELETED_ERROR_MESSAGE;
      dispatch(setViewAccessPublicViewURL(oldPublicUrl));

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      dispatch(setViewAccessShowDeletePublicURLConfirmationDialog(false));
    }
  };

  const handleCopyPublicUrl = (text: string, result: boolean) => {
    if (text && result) {
      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.INFO,
        title: PUBLIC_URL_COPY_SUCCESS_MESSAGE,
      });
    } else {
      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: PUBLIC_URL_COPY_ERROR_MESSAGE,
      });
    }
  };

  return (
    <VStack align={'left'} alignItems={'center'}>
      <ConfirmationDialog
        closeButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        description="Are you sure you want to delete this Public URL?"
        handleClose={() => dispatch(setViewAccessShowDeletePublicURLConfirmationDialog(false))}
        closeConfirmDialog={() => dispatch(setViewAccessShowDeletePublicURLConfirmationDialog(false))}
        handleConfirm={handleDeletePublicURL}
        isOpen={showDeletePublicURLConfirmationDialog}
        title="Warning"
      />

      <span>Public URL</span>
      <HStack>
        {publicViewURLValue === undefined && (
          <Button
            colorScheme={'blue'}
            isDisabled={isLoading}
            isLoading={generatePublicURLResponse.isLoading}
            onClick={handleGeneratePublicURL}
            size={'sm'}
          >
            Generate URL
          </Button>
        )}
        {publicViewURLValue && (
          <>
            <CopyToClipboard text={publicViewURLValue} onCopy={handleCopyPublicUrl}>
              <Button colorScheme={'blue'} isDisabled={isLoading} size={'sm'}>
                Copy
              </Button>
            </CopyToClipboard>
            <Button
              colorScheme={'red'}
              isLoading={deletePublicURLResponse.isLoading}
              isDisabled={isLoading}
              onClick={() => dispatch(setViewAccessShowDeletePublicURLConfirmationDialog(true))}
              size={'sm'}
            >
              Delete
            </Button>
          </>
        )}
      </HStack>
    </VStack>
  );
};

export default PublicURLControls;
