import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';

import { useAppDispatch } from '@/store/hooks';
import { setViewEditorDraftNavIsBlocked } from '@/store/slices/viewEditor.slice';

const NavBlocker = (): React.JSX.Element => {
  const { errors } = useFormikContext();

  const prevFieldErrors = React.useRef(errors);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!_.isEqual(errors, prevFieldErrors.current)) {
      prevFieldErrors.current = errors;
      // eslint-disable-next-line no-magic-numbers
      if (Object.keys(errors).length > 0) {
        dispatch(setViewEditorDraftNavIsBlocked(true));

        return;
      }

      dispatch(setViewEditorDraftNavIsBlocked(false));
    }
  }, [dispatch, errors]);

  return <></>;
};

export default NavBlocker;
