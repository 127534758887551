import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store/store';

interface UiState {
  isCloneViewModalOpen: boolean;
  isConfirmActionModalOpen: boolean;
  isCriticalError: { message: string; stack: string; origin: string } | null;
  isLoading: {
    isAuthenticating: boolean;
    isFetchingDepartments: boolean;
    isFetchingPersonnel: boolean;
    isFetchingTemplates: boolean;
    isFetchingView: boolean;
    isFetchingViews: boolean;
  };
  isViewAccessEditorOpen: boolean;
  isViewEditDrawerOpen: boolean;
}

const initialState: UiState = {
  isCloneViewModalOpen: false,
  isConfirmActionModalOpen: false,
  isCriticalError: null,
  isLoading: {
    isAuthenticating: false,
    isFetchingDepartments: false,
    isFetchingPersonnel: false,
    isFetchingTemplates: false,
    isFetchingView: false,
    isFetchingViews: false,
  },
  isViewAccessEditorOpen: false,
  isViewEditDrawerOpen: false,
};

export const uiSlice = createSlice({
  initialState,
  name: 'uiStore',
  reducers: {
    setCriticalError: (state, action) => {
      state.isCriticalError = action.payload;
    },
    toggleCloneViewModal: (state) => {
      state.isCloneViewModalOpen = !state.isCloneViewModalOpen;
    },
    toggleConfirmActionModal: (state) => {
      state.isConfirmActionModalOpen = !state.isConfirmActionModalOpen;
    },
    toggleIsAuthenticating: (state) => {
      state.isLoading.isAuthenticating = !state.isLoading.isAuthenticating;
    },
    toggleIsLoadingDepartments: (state) => {
      state.isLoading.isFetchingDepartments = !state.isLoading.isFetchingDepartments;
    },
    toggleIsLoadingPersonnel: (state) => {
      state.isLoading.isFetchingPersonnel = !state.isLoading.isFetchingPersonnel;
    },
    toggleIsLoadingTemplates: (state) => {
      state.isLoading.isFetchingTemplates = !state.isLoading.isFetchingTemplates;
    },
    toggleIsLoadingView: (state) => {
      state.isLoading.isFetchingView = !state.isLoading.isFetchingView;
    },
    toggleIsLoadingViews: (state) => {
      state.isLoading.isFetchingViews = !state.isLoading.isFetchingViews;
    },
    toggleViewAccessEditor: (state) => {
      state.isViewAccessEditorOpen = !state.isViewAccessEditorOpen;
    },
    toggleViewEditDrawer: (state) => {
      state.isViewEditDrawerOpen = !state.isViewEditDrawerOpen;
    },
  },
});

export const selectIsCloneViewModalOpen = (state: RootState) => state.uiStore.isCloneViewModalOpen;
export const selectIsConfirmActionModalOpen = (state: RootState) => state.uiStore.isConfirmActionModalOpen;
export const selectIsCriticalError = (state: RootState) => state.uiStore.isCriticalError;
export const selectIsUiLoading = (state: RootState) => {
  const { isLoading } = state.uiStore;

  return Object.keys(isLoading).some((key) => isLoading[key as keyof typeof isLoading]);
};
export const selectIsViewAccessEditorOpen = (state: RootState) => state.uiStore.isViewAccessEditorOpen;
export const selectIsViewEditDrawerOpen = (state: RootState) => state.uiStore.isViewEditDrawerOpen;
export const selectUi = (state: RootState) => state.uiStore;

export default uiSlice.reducer;
