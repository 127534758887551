import { Button, Flex, HStack, Select, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { FaBackward, FaFastBackward, FaFastForward, FaForward } from 'react-icons/fa';

import UIConfig from '@/config/ui.config';

interface PaginationControlsProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  goToPage: (pageIndex: number) => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  isZeroBased?: boolean;
  width?: string;
}

const PaginationControls = (props: PaginationControlsProps): JSX.Element => {
  const {
    canNextPage,
    canPreviousPage,
    goToPage,
    isZeroBased = false,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageSize,
    width = '560px',
  } = props;

  // console.log('paginationControls pageIndex', props.pageIndex);
  // console.log('paginationControls pageCount', props.pageCount);
  // console.log('paginationControls canNextPage', props.canNextPage);

  // eslint-disable-next-line no-magic-numbers
  const startIndex = isZeroBased ? 0 : 1;
  // eslint-disable-next-line no-magic-numbers
  const endIndex = isZeroBased ? pageCount - 1 : pageCount;

  return (
    <Flex className="pagination" w={width} alignItems={'center'} marginBottom={'8px'} justifyContent={'space-between'}>
      <Flex gap={'1'}>
        <Button onClick={() => goToPage(startIndex)} disabled={!canPreviousPage} size={'xs'}>
          <FaFastBackward />
        </Button>
        <Spacer />
        <Button onClick={() => canPreviousPage && previousPage()} disabled={!canPreviousPage} size={'xs'}>
          <FaBackward />
        </Button>
        <Spacer />
        <Button onClick={() => canNextPage && nextPage()} disabled={!canNextPage} size={'xs'}>
          <FaForward />
        </Button>
        <Spacer />
        <Button onClick={() => goToPage(endIndex)} disabled={!canNextPage} size={'xs'}>
          <FaFastForward />
        </Button>
      </Flex>
      <Spacer />
      <HStack w={'fit-content'}>
        <Text fontSize={'sm'}>Page:</Text>
        <Text fontSize={'sm'} fontWeight={'bold'}>
          {/* eslint-disable-next-line no-magic-numbers */}
          {pageCount === 0 ? `- of -` : `${pageIndex + 1} of ${pageCount}`}
        </Text>
      </HStack>
      <Spacer />
      {/* <HStack> */}
      {/*   <Text fontSize={'sm'}>|</Text> */}
      {/*   <Spacer /> */}
      {/*   <Text fontSize={'sm'}>Go to page:</Text> */}
      {/*   <NumberInput */}
      {/*     defaultValue={1} */}
      {/*     h={'24px'} */}
      {/*     max={pageCount} */}
      {/*     min={1} */}
      {/*     onChange={(value: string) => { */}
      {/*       const page = Number(value); */}
      {/*       goToPage(page); */}
      {/*     }} */}
      {/*     size={'sm'} */}
      {/*     value={pageIndex + 1} */}
      {/*     w={'90px'} */}
      {/*   > */}
      {/*     <NumberInputField h={'24px'} /> */}
      {/*     <NumberInputStepper h={'24px'}> */}
      {/*       <NumberIncrementStepper /> */}
      {/*       <NumberDecrementStepper /> */}
      {/*     </NumberInputStepper> */}
      {/*   </NumberInput> */}
      {/* </HStack> */}
      <Spacer />
      <Select
        variant={'outline'}
        onChange={(event) => setPageSize(Number(event.target.value))}
        value={pageSize}
        size={'sm'}
        w={"fit-content"}
        h={'24px'}
      >
        {UIConfig.PAGINATION_PAGE_SIZE_OPTIONS.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default PaginationControls;
