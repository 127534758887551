import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { useAppDispatch } from '@/store/hooks';
import { uiSlice } from '@/store/slices/ui.slice';

export interface ConfirmActionModalProps {
  readonly body: JSX.Element;
  readonly confirmAction: () => void;
  readonly header: string;
  readonly isOpen: boolean;
  readonly cancelAction?: () => void;
  readonly cancelActionButtonText?: string;
  readonly confirmActionButtonText?: string;
  readonly isLoading?: boolean;
}

const ConfirmActionModal = (props: ConfirmActionModalProps): JSX.Element => {
  const {
    body,
    cancelAction,
    cancelActionButtonText = 'Cancel',
    confirmAction,
    confirmActionButtonText = 'Confirm',
    header,
    isLoading = false,
    isOpen,
  } = props;

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(uiSlice.actions.toggleConfirmActionModal());
  };

  const handleCancel = () => {
    cancelAction && cancelAction();
    handleClose();
  };
  const handleConfirm = () => {
    confirmAction();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent border={'2px solid rgba(0,0,0,0.4)'}>
        <ModalHeader textAlign={'center'} fontSize={'18px'}>
          {header}
        </ModalHeader>
        <ModalCloseButton />
        <VStack spacing={5} align={'center'}>
          <ModalBody>{body}</ModalBody>
          <ButtonGroup paddingBottom={'15px'}>
            <Button colorScheme={'red'} onClick={handleConfirm} isLoading={isLoading} loadingText={'Pending'}>
              {confirmActionButtonText}
            </Button>
            <Button colorScheme={'blue'} onClick={handleCancel} variant={'outline'}>
              {cancelActionButtonText}
            </Button>
          </ButtonGroup>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmActionModal;
