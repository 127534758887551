import './App.css';

import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { ROUTES } from '@/constants/config';
import useDepartments from '@/hooks/useDepartments';
import LoadingPage from '@/pages/loading/Loading.page';
import ViewsPage from '@/pages/views/Views.page';
import { useAppSelector } from '@/store/hooks';

import ProtectedRoute from './components/protected-route/ProtectedRoute';
import PersonnelPage from './pages/personnel/Personnel.page';
import UnauthorizedPage from './pages/unauthorized/Unauthorized.page';

const App = (): React.JSX.Element => {
  const fetchDepartments = useDepartments();
  
  const {isAuthenticated} = useAppSelector((state) => state.auth);
  
  React.useEffect(()=>{
    if(!isAuthenticated) return;

    fetchDepartments();
  },[fetchDepartments, isAuthenticated]);
  
  return (
    <>
      <Routes>
        <Route path={ROUTES.ROOT} element={<LoadingPage />} />
        <Route
          path={ROUTES.PERSONNEL}
          element={
            <ProtectedRoute>
              <PersonnelPage />
            </ProtectedRoute>
          }
        />
        <Route path={ROUTES.UNAUTHORIZED} element={<UnauthorizedPage />} />
        <Route
          path={ROUTES.VIEWS}
          element={
            <ProtectedRoute>
              <ViewsPage />
            </ProtectedRoute>
          }
        />
        <Route path={'*'} element={<UnauthorizedPage />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default App;
